<template>
  <div>
    <details-transactions-made/>
  </div>
</template>

<script>
import DetailsTransactionsMade from "@core/components/details-billing/DetailsTransactionsMade";

export default {
  name: "DetailsTransactions",
  components: {
    DetailsTransactionsMade
  }
}
</script>

<style scoped>

</style>